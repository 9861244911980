import React, { useState, useEffect } from "react";
import { Row, Col, Container, Input, Button, Alert } from "reactstrap";
import { useLocation } from "react-router-dom";

import "./styles/Feedback.scss";
import ReactStars from "component/StarRating";
import { useSelector, useDispatch } from "react-redux";
import { IRootReducerState } from "common/interface/store/reducer/Reducer";
import {
	getFeedbackAction,
	getFeedbackDataAction,
	submitFeedbackDataAction
} from "store/actions/department/deparmentActions";
import FeedbackCard from "component/FeedbackCard";
import { FacebookShareButton, TwitterShareButton } from "react-share";

const Feedback = () => {
	const dispatch = useDispatch();
	const useQuery = () => new URLSearchParams(useLocation().search);
	const query = useQuery();
	const pathData = window.location.pathname.split("/");
	const feedbackId = pathData[pathData.length - 1];
	const [data, setData] = useState<any>(null);
	const [selectedStars, setSelectedStars] = useState(0);
	const [selectedScore, setSelectedScore] = useState(0);
	const [starText, setStarText] = useState("");
	const [feedbackText, setFeedbackText] = useState("");
	const [showAlert, setShowAlert] = useState("");
	const [error, setError] = useState<any>([]);

	/**
	 * Get current department key
	 */
	const currentDepartment = useSelector(
		(state: IRootReducerState) => state.departmentReducer.currentDepartment
	);

	const currentDepartmentKey = currentDepartment?.slug;
	const rating = currentDepartment
		? (currentDepartment.feedback.average * 2) / 10
		: 10;

	/**
	 * Get Feedback
	 */
	const feedback = useSelector(
		(state: IRootReducerState) => state.departmentReducer.feedback
	);

	useEffect(() => {
		if (query.get("hash") && feedbackId)
			dispatch(
				getFeedbackDataAction(
					(feedbackId as unknown) as number,
					query.get("hash") || "",
					(status, res) => {
						setData(res);
					}
				)
			);
		dispatch(getFeedbackAction({}, 12));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const onClickRating = (ratingCount: number) => {
		setSelectedStars(ratingCount);
	};

	const getErrors = (obj: any, errors = error) => {
		const arr = errors;
		for (let key in obj) {
			if (Array.isArray(obj[key])) {
				arr.push(obj[key][0]);
			} else if (typeof obj[key] == "object") {
				getErrors(obj[key], arr);
			} else {
				arr.push(obj[key]);
			}
		}
		setError(arr);
	};

	const submitFeedback = () => {
		dispatch(
			submitFeedbackDataAction(
				feedbackId,
				query.get("hash"),
				{
					rating: selectedStars * 10,
					content: feedbackText
				},
				(status, response) => {
					if (status) {
						setData(response.data);
						setSelectedStars(0);
						setSelectedScore(0);
						setFeedbackText("");
						setShowAlert("success");
					} else {
						getErrors(response, []);
						setShowAlert("danger");
					}
					window.scrollTo({
						top: 0,
						behavior: "smooth"
					});
				}
			)
		);
	};

	const showRatingText = (ratingCount: number) => {
		let text = "";
		const key = currentDepartmentKey === "couriers" ? "Koeriers" : "Auto’s";
		switch (ratingCount) {
			case 1:
				setSelectedScore(2);
				text = `Slechte ervaring met Vandaag ${key} 😪`;
				break;
			case 2:
				setSelectedScore(4);
				text = `Matige ervaring met Vandaag ${key} 😔`;
				break;
			case 3:
				setSelectedScore(6);
				text = `Normale ervaring met Vandaag ${key} 😀`;
				break;
			case 4:
				setSelectedScore(8);
				text = `Goede ervaring met Vandaag ${key} 😃`;
				break;
			case 5:
				setSelectedScore(10);
				text = `Waanzinnige ervaring met Vandaag ${key} 😎`;
				break;
		}
		setStarText(text);
	};

	return (
		<div className="give-feedback-wrap">
			<Container>
				<Row>
					<Col md="8">
						<div className="main-heading">
							<b>
								{currentDepartment?.name} wordt landelijk door ruim{" "}
								{currentDepartment?.feedback.total} klanten beoordeeld met een
								score van {rating.toFixed(1)} van de 10. Beoordeel ons ook met
								sterren en laat uw persoonlijke ervaring achter!
							</b>
						</div>
					</Col>
				</Row>
				{showAlert && (
					<Row className="alert-wrap">
						<Col md="12">
							<Alert color={showAlert}>
								{showAlert === "success" ? (
									"Bedankt voor je review."
								) : (
									<ul>
										{error.map((err: any, i: number) => (
											<li key={i}>{err}</li>
										))}
									</ul>
								)}
							</Alert>
						</Col>
					</Row>
				)}
				{data && !data.tags && (
					<Row className="alert-wrap">
						<Col md="12">
							<Alert color="danger">{data}</Alert>
						</Col>
					</Row>
				)}
				{data && data.tags && (
					<Row
						className={`feedback-input-wrap ${
							showAlert ? "is-alert-active" : ""
						}`}
					>
						<Col md="8">
							<div className="give-feedback">
								{(!data.can_be_created || showAlert === "success") && (
									<div className="feedback-given">
										<span>Bedankt! Uw review is verzonden.</span>
										<div className="feedback-textarea">
											<span>Uw Review</span>
											<Input
												type="textarea"
												disabled={true}
												style={{ height: "auto", minHeight: "230px" }}
											/>
											<div className="feedback-details-wrap">
												<div className="d-flex align-items-center">
													<ReactStars
														className="stars"
														half={false}
														valueProp={data.rating / 10}
														color1="#53554c"
														count={5}
														edit={false}
														size={50}
														color2={"#ffd700"}
													/>
													<div className="user-name">{data.name}</div>
												</div>
												<div className="feedback-content">{data.content}</div>
											</div>
										</div>
										<div className="share-review-text">
											Deel uw review ook op social media!
										</div>
										<div className="">
											<FacebookShareButton
												className="share-icon"
												url={window.location.href}
											>
												<svg
													width="39"
													height="39"
													focusable="false"
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 448 512"
												>
													<path
														fill="#ffffff"
														d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
													></path>
												</svg>
											</FacebookShareButton>
											<TwitterShareButton
												className="share-icon"
												url={window.location.href}
											>
												<svg
													width="39"
													height="39"
													focusable="false"
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 448 512"
												>
													<path
														fill="#ffffff"
														d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5 0 86.7-66 186.6-186.6 186.6-37.2 0-71.7-10.8-100.7-29.4 5.3.6 10.4.8 15.8.8 30.7 0 58.9-10.4 81.4-28-28.8-.6-53-19.5-61.3-45.5 10.1 1.5 19.2 1.5 29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 0 1-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1 32.3 39.8 80.8 65.8 135.2 68.6-9.3-44.5 24-80.6 64-80.6 18.9 0 35.9 7.9 47.9 20.7 14.8-2.8 29-8.3 41.6-15.8-4.9 15.2-15.2 28-28.8 36.1 13.2-1.4 26-5.1 37.8-10.2-8.9 13.1-20.1 24.7-32.9 34z"
													></path>
												</svg>
											</TwitterShareButton>
										</div>
									</div>
								)}
								{data.can_be_created && showAlert != "success" && (
									<>
										<b>Score: {selectedScore}/10</b>
										<div className="stars-wrap">
											<ReactStars
												className="stars"
												half={false}
												valueProp={selectedStars}
												color1="#53554c"
												count={5}
												onChange={onClickRating}
												onMouseOver={showRatingText}
												onMouseLeave={() => {
													setSelectedScore(selectedStars * 2);
													setStarText("");
												}}
												size={50}
												color2={"#ffd700"}
											/>
											<span className="ratings-text">{starText}</span>
										</div>
										<>
											<Row>
												<Col sm="6">
													<span className="label">Naam</span>
													<br />
													<span className="value">{data && data.name}</span>
												</Col>
												{currentDepartmentKey === "couriers" && (
													<Col sm="3">
														<span className="label">Type zending</span>
														<br />
														<span className="value">
															{data && data.tags.shipment_type}
														</span>
													</Col>
												)}
												{currentDepartmentKey === "vehicles" && (
													<>
														<Col sm="3">
															<span className="label">Merk</span>
															<br />
															<span className="value">
																{data && data.tags.brand}
															</span>
														</Col>
														<Col sm="3">
															<span className="label">Model</span>
															<br />
															<span className="value">
																{data && data.tags.model}
															</span>
														</Col>
													</>
												)}
											</Row>
											<div className="feedback-textarea">
												<span>Uw Review</span>
												<Input
													type="textarea"
													style={{ height: "auto" }}
													name="name"
													placeholder={
														currentDepartmentKey === "couriers"
															? "Bijv. Ik heb een GEWELDIGE ervaring gehad bij Vandaag"
															: "Bijv. Ik heb een GEWELDIGE ervaring gehad bij Vandaag"
													}
													value={feedbackText}
													onChange={e => setFeedbackText(e.target.value)}
												/>
											</div>
											<Button
												className={`btn-sr-yellow ${
													!feedbackText ? "disabled" : ""
												}`}
												disabled={!feedbackText}
												onClick={submitFeedback}
											>
												Verstuur
											</Button>
										</>
									</>
								)}
							</div>
						</Col>
						<Col md="4">
							<div>
								<b>Waarvoor gebruiken wij uw review?</b>
								<p>
									Wij gebruiken uw review om er voor te zorgen dat wij op zoveel
									mogelijk punten blijven verbeteren!
								</p>

								<b>Worden uw gegevens bloot gesteld?</b>
								<p>
									Het antwoord op deze vraag is: 'nee'. Wij gebruiken uw review
									enkel voor het verbeteren van onze service naar u!
								</p>
							</div>
						</Col>
					</Row>
				)}
				{feedback.length ? (
					<>
						<Row>
							<Col md="12">
								<h1 className="feedback-heading">
									Vandaag{" "}
									{currentDepartmentKey === "couriers" ? "Kouriers" : "Auto’s"}{" "}
									Reviews
								</h1>
							</Col>
						</Row>
						<Row>
							{feedback.map((item, i) => (
								<Col key={i} md="4">
									<FeedbackCard item={item} key={`feedback${i}`} />
								</Col>
							))}
						</Row>
					</>
				) : null}
			</Container>
		</div>
	);
};

export default Feedback;
