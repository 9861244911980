import React, { useState, useRef, FC } from "react";
import { Row, Col, Input, Alert } from "reactstrap";
import { useLocation, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "./WinInvoice.scss";
import { useEffect } from "react";
import axios from "axios";
import { getOrderDetails, submitOrderComplete } from "store/apiService/department/departmentServices";
import { formatPrice } from "common/utilities";
import { getUploadUrl } from "store/apiService/vehicles/vehiclesServices";

const WinInvoice: FC = () => {
	const [orderData, setOrderData] = useState<any>(null);
	const [error, setError] = useState<any>(null);
	const [invoiceAmount, setInvoiceAmount] = useState<any>();
	const [invoiceId, setInvoiceId] = useState<string>();
	const useQuery = () => new URLSearchParams(useLocation().search);
	const [imageUploadError, setImageUploadError] = useState("");
	const [invoiceIsRequiredError, setInvoiceIsRequiredError] = useState(false);
	const [invoiceAmountIsRequiredError, setInvoiceAmountIsRequiredError] = useState(false);
	const query = useQuery();
	let { id } = useParams<any>();

	useEffect(() => {
		if (id && query.get("hash")) {
			getOrderDetails(id, query.get("hash"))
				.then(({ data }) => {
					setOrderData(data);
				})
				.catch(err => {
					setError(
						err.response ? err.response.data.message : "Please try again later"
					);
				});
		}
	}, [id]);

	const uploadInvoice = (e: any) => {
		const imageName = e.target.value.split("\\")[
		e.target.value.split("\\").length - 1
			];
		const uuid = uuidv4();
		const file = e.target.files[0];
		setImageUploadError("");
		// setShowImageUploadProgress(true);
		getUploadUrl(uuid, file.type, file.size)
			.then(res => {
				axios.put(res.upload_url, file, {
					headers: {
						"Content-Type": file.type,
						"Accept-Language": "nl"
					},
					onUploadProgress: (progressEvent: any) => {
						const progressVal = Math.round(
							(progressEvent.loaded * 100) / progressEvent.total
						);


						// progressBarRef.current.setAttribute("value", progressVal);
						// progressBarRef.current.previousElementSibling.textContent = `${progressVal}%`;
						// if (progressVal === 100) {
						// 	progressBarRef.current.previousElementSibling.textContent =
						// 		"Upload complete!";
						// }
					}
				});

				setInvoiceId(res.secret_id);
				setInvoiceIsRequiredError(false)
			})
			.catch(err => {
				// setShowImageUploadProgress(false);
				setImageUploadError(
					err.response ? err.response.data.message : "Please try again later"
				);
			});
	};


	const onClickSubmit = () => {
		let price = invoiceAmount;
		if(invoiceId === undefined) {
			setInvoiceIsRequiredError(true);
			return;
		}

		if(invoiceAmount === undefined || invoiceAmount === "") {
			setInvoiceAmountIsRequiredError(true);
			return;
		}


		if (
			invoiceAmount &&
			(invoiceAmount.includes(",") || invoiceAmount.includes("."))
		) {
			price = invoiceAmount.replace(/[,.]/g, "");
		} else {
			price = invoiceAmount * 100;
		}
		submitOrderComplete(id, query.get("hash"), {
			price: parseInt(price),
			attachment_id: [invoiceId]
		})
			.then(res => {
				setError("success");
			})
			.catch(err => {
				setError(
					err.response ? err.response.data.message : "Please try again later"
				);
			});
	};

	return (
		<>
			{error && !orderData && (
				<Alert className="text-left mt-5 ml-auto mr-auto" color="danger">
					{error}
				</Alert>
			)}
			{orderData && (
				<div className="main-wrapper">
					<h2 className="mb-4">WIN UW FACTUURBEDRAG TERUG!</h2>
					{error && error === "success" && (
						<Alert className="text-left" color="success">
							Successfully submitted!
						</Alert>
					)}

					<Row>
						<Col md={5}>
							<div className="left-side">
								<b className="mb-2">Volledig factuurbedrag terugwinnen?</b>
								<p>
									Upload uw factuur en vul in wat u heeft afgerekend en maak kans om het volledige
									bedrag terug te winnen.
								</p>
								<label htmlFor="name">Naam</label>
								<Input
									type="text"
									name="name"
									value={orderData.name}
									disabled
									className="mb-2"
								/>
								<label htmlFor="uploadInvoice">Factuur</label>
								<div className="input-group mb-3">
									<input required type="file" className="form-control" onChange={uploadInvoice} id="uploadInvoice"/>
								</div>
								{imageUploadError && <p className="text-danger">{imageUploadError}</p>}
								{invoiceIsRequiredError && <p className="text-danger">Factuur is verplicht</p>}

								<label htmlFor="invoiceAmount">Factuurbedrag</label>
								<Input
									value={invoiceAmount}
									onChange={e => {
										setInvoiceAmount(e.target.value);
										setInvoiceAmountIsRequiredError(false);
									}}
									className=" mt-2"
									type="number"
									name="name"
									placeholder="€ Vul uw factuurbedrag in."
								/>
								{invoiceAmountIsRequiredError && <p className="text-danger">Factuurbedrag is verplicht</p>}
								<button
									type="button"
									className="btn btn-sr-yellow btn btn-secondary mt-2"
									onClick={onClickSubmit}
								>
									VERZENDEN
								</button>
							</div>
						</Col>
						<Col md={7}>
							<div className="right-side">
								<h2>VORIGE WINNAAR</h2>
								<p>
									Dhr. G. van Gorp <br/> uit Eindhoven
								</p>
								<b>Gewonnen bedrag</b>
								<br/>
								<h2 className="mb-5">€ {formatPrice(43282)}</h2>
							</div>
						</Col>
					</Row>
				</div>
			)}
		</>
	);
};

export default WinInvoice;
