import * as apiService from "../index";
import { GET_ALL_DEPARTMENT } from "../../actionTypes/department/departmentActionTypes";
import { getCredentials } from "common/utilities/department/department";

/**
 * Get current couriers
 */
export const getCurrentDepartment = (department: string = "") => {
	let apiCall = "system/departments/identify-by-hostname";
	if (department) {
		if(department.startsWith('?')) {
			apiCall = `system/departments${department}`;
		} else {
			apiCall = `system/departments/${department}`;
		}
	}

	return apiService.get(apiCall);
};

/**
 * get couriers data
 */
export const getDepartment = () => {
	return (dispatch: any) => {
		apiService
			.get("/system/departments")
			.then((response: any) => {
				if (response.data) {
					dispatch({ type: GET_ALL_DEPARTMENT, payload: response.data });
				}
			})
			.catch((error: any) => console.log(error));
	};
};

/**
 * FeedBack
 */
export const getFeedBack = (data: any, minimum: number) => {
	let tags = JSON.stringify(data);                                                           // For Encoded URL
	let apiCall = `feedback-service/search?tags=${btoa(tags)}&minimum=${minimum}`;
	// let tags = `tags_brand=${data?.brand}&tags_construction_year=${data?.construction_year}&tags_fuel_type=${data?.fuel_type}&tags_model=${data?.model}`
	// let apiCall = `feedback-service/search?${tags}&minimum=${minimum}`;                    // For Parms URL nginx Error 400
	return apiService.get(apiCall);
};

/**
 * Invoice pay
 */
export const payInvoice = (
	invoiceId: string,
	hash: string,
	data: { cancel_url: string; success_url: string }
) => {
	let apiCall = `invoices/${invoiceId}/pay?hash=${hash}`;
	return apiService.put(apiCall, data);
};

/**
 * Order pay
 */
export const payOrder = (orderId: string, hash: string) => {
	let apiCall = `orders/${orderId}/pay?hash=${hash}`;
	return apiService.put(apiCall);
};

/**
 * Invoice pay
 */
export const quoteRequest = (data: any, department: string) => {
	const credentials = getCredentials(department);
	return apiService.post(
		"orders/quotes",
		data,
		{},
		{ "X-Idempotency": credentials.uuid }
	);
};

/**
 * FeedBack data
 */
export const getFeedBackData = (id: number, hash: string) => {
	let apiCall = `feedback-service/feedbacks/${id}?secret_hash=${hash}`;
	return apiService.get(apiCall);
};

/**
 * Submit FeedBack data
 */
export const submitFeedBackData = (
	id: string,
	hash: any,
	data: { content: string; rating: number }
) => {
	let apiCall = `feedback-service/feedbacks/${id}/review?secret_hash=${hash}`;
	return apiService.post(apiCall, data);
};

/**
 * Get order data
 */
export const getOrderDetails = (id: string, hash: any) => {
	let apiCall = `orders/${id}/complete?hash=${hash}`;
	return apiService.get(apiCall);
};

/**
 * Get order data
 */
export const submitOrderComplete = (
	id: string,
	hash: any,
	data: {
		price: number;
		attachment_id?: any[]; // Ensure it's typed as an array
	}
) => {
	// Ensure attachment_id is always an array, even if it's not provided
	const formattedData = {
		...data,
		attachment_id: Array.isArray(data.attachment_id) ? data.attachment_id : [] // Make sure it's an array
	};

	let apiCall = `orders/${id}/complete?hash=${hash}`;
	return apiService.put(apiCall, formattedData);
};


/**
 * Unsubscription APK
 */
export const unsubApk = (vehicleId: string, hash: string) => {
	let apiCall = `profile/properties/vehicles/unsubscribe/${vehicleId}?hash=${hash}`;
	return apiService.put(apiCall);
};
